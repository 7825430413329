import React, {useState} from "react";
import {useSelector} from "react-redux";
import {getTerminalById, updateTerminal} from "../../store/terminals";
import {getBodyById} from "../../store/body";
import useTerminals from "../../hooks/useTerminals";
import Work from "../ui/works/work";
import Button from "../common/button";
import AWS from "aws-sdk";

const TerminalInfo = () => {
    const [data, setData] = useState()

    const {params, dispatch, works, history} = useTerminals(data, setData)

    const {id} = params

    const currentTerminal = useSelector(getTerminalById(id))

    const currentBody = useSelector(getBodyById(currentTerminal.body))

    const transformData = (data) => {
        const result = getWorksListByIds(data).map(work => ({
            label: work.name,
            value: work._id,
            sum: work.sum
        }))
        return result
    }

    function getWorksListByIds(worksIds) {
        const worksArray = []
        for (const workId of worksIds) {
            for (const work of works) {
                if (work._id === workId) {
                    worksArray.push(work)
                    break
                }
            }
        }
        return worksArray
    }

    //----------------------------------------------

    const s3 = new AWS.S3({
        endpoint: "https://storage.yandexcloud.net", // Yandex Object Storage endpoint
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY, // Замените на ваш access key
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY, // Замените на ваш secret key
        region: "ru-central1", // Регион Yandex Cloud
        s3ForcePathStyle: true, // Для совместимости с Amazon S3
    });

    const [photos, setPhotos] = useState([]);

    const handleUploadSuccess = (uploadedPhotos) => {
        setPhotos([...photos, ...uploadedPhotos]);
    };

    const handleDeleteImg = (photoUrl) => {
        const updatedPhotos = photos.filter((photo) => photo.Location !== photoUrl);
        setPhotos(updatedPhotos);
    };

    const handleDelete = async (photo) => {
        const params = {
            Bucket: "work-terminals.ru", // Имя вашего bucket
            Key: photo.Key, // Путь и имя файла
        };
        try {
            // Удаление объекта из Yandex Cloud
            await s3.deleteObject(params).promise();
            handleDeleteImg(photo.Location); // Удаление из состояния после успешного удаления из облака
            const filterPhoto = currentTerminal.img.filter(image => image.Location !== photo.Location)
            const newData = {
                ...currentTerminal,
                img: filterPhoto
            }
            console.log('newData', newData)
            dispatch(updateTerminal({...newData}))
        } catch (error) {
            console.error("Error deleting photo:", error);
        }
    };

    const currentWorks = transformData(currentTerminal.works)

    return (
        <div className='container flex-column mt-2'>
            <div>

                <Button
                    label='Назад'
                    type='button'
                    color='light'
                    onClick={() => history.goBack()}
                    icon={<i className='bi bi-arrow-left'></i>}
                    rounded='rounded-1'
                />
            </div>
            <div>
                <div className='mt-2'>
                    Информация:
                </div>
                <div>{currentTerminal.city}</div>
                <div>Номер: {currentTerminal.number}</div>
                <div>Корпус: {currentBody.name}</div>
                <div className=''>
                    <div>Доп. работы:</div>
                    {currentWorks.map(work => (
                            <Work key={work.value} name={work.label} sum={work.sum} _id={work.value}/>
                        )
                    )}
                    <div>
                    </div>
                </div>
                <div className='d-flex flex-wrap'>
                    {currentTerminal.img && currentTerminal.img.map((photo) => (
                        <div key={photo.Key} className='position-relative m-4'>
                            <img src={photo.Location} alt="" style={{width: '150px', objectFit: 'cover'}}
                                 className='mt-2'/>
                            <div className='position-absolute top-0 end-0'>
                                <Button
                                    type='button'
                                    color='danger'
                                    size='btn-sm'
                                    rounded='rounded-1'
                                    icon={<i className="bi bi-trash"></i>}
                                    onClick={() => handleDelete(photo)}
                                />
                            </div>
                        </div>
                    ))}
                </div>
                {/*<div>*/}
                {/*    <h1>Photo Uploader</h1>*/}
                {/*    <PhotoUploader onUploadSuccess={handleUploadSuccess} month={currentTerminal.month.label} numberTerminal={currentTerminal.number}/>*/}
                {/*    <PhotoGallery photos={photos} onDelete={handleDelete} />*/}
                {/*</div>*/}
            </div>
        </div>
    )
}

export default TerminalInfo
